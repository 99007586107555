import _ from 'lodash'
import { Link, useParams } from 'react-router-dom'

const menu = [
	{ name: 'Home', link: './' },
	{ name: 'About', options: [ 'About Us', 'The Rules' ] },
	{ name: 'Latest News' },
	{ name: 'Lineage' },
	{ name: 'World Title Fights', options: [ 'Years 1991 - 2020' ] },
	{ name: 'Compliance', options: [ 'Compliance', 'Non compliant Fighters', 'Non compliant Rule 100', 'Non compliant Rule 35' ] },
	{ name: 'Rankings', options: [ 'Heavyweight 200', 'Cruiserweight 200', 'Light-heavyweight 175', 'Super-middleweight 168', 'Middleweight 160', 'Junior-middleweight 154', 'Welterweight 147', 'Junior-welterweight 140', 'Lightweight 135', 'Super-featherweight 130', 'Featherweight 126', 'Super-bantamweight 122', 'Bantamweight 118', 'Super-flyweight 115', 'Flyweight 112', 'Junior-flyweight 108', 'Straw-weight 105' ] },
	{ name: 'Contact Us' }
]

export function Menu() {
	const { link: currentLink = null } = useParams()
	return (
		<nav className='navbar navbar-default navbar-fixed-top' role='navigation'>
			<div className='navbar-header'>
				<button type='button' className='navbar-toggle' data-toggle='collapse' data-target='.navbar-0-collapse'>
					<span className='sr-only'>Toggle navigation</span>
					<span className='icon-bar'></span>
					<span className='icon-bar'></span>
					<span className='icon-bar'></span>
				</button>
				<Link to='/' className='navbar-brand'>PBO</Link>
			</div>
			<div className='collapse navbar-collapse navbar-0-collapse'>
				<ul className='nav navbar-nav'>
					{menu.map(({ name, link, options }, i) =>
						options ? (
								<li key={i} className='dropdown'>
									<a href={link} className='dropdown-toggle' data-toggle='dropdown'>{name} <b className='caret'></b></a>
									<ul className='dropdown-menu'>
										{options.map((o, i) => {
											const link = _.kebabCase(o)
											return <li key={i} className={currentLink === link ? 'active' : ''}><Link to={`/${link}`}>{o}</Link></li>
										})}
									</ul>
								</li>
							)
							: <li key={i}><Link to={`/${link || _.kebabCase(name)}`}>{name}</Link></li>
						
					)}
				</ul>
			</div>
		</nav>
	)
}

export function Footer() {
	return (
		<footer>
			<div className='container'>
				<div className='row'>
					<div className='col-md-12'>
						<div className='row'>
							<div className='col-md-4'>
								<div className='widget'>
									<h5>Contact</h5>
									<hr />
									<p>Premier Boxing Organisation </p>
									<hr />
									<i className='icon-home'>
									</i> Luckland Limited, Codrington, Chipping Sodbury, Bristol,<br /> BS37 6RZ<hr />
									<i className='icon-phone'>
									</i> &nbsp; 07976 363 190 <hr />
									<i className='icon-envelope-alt'>
									</i> &nbsp; <Link to='mailto:Adrianstiff@aol.com'>Adrianstiff@aol.com</Link>
									<hr />
									<div className='social'> <Link to='#'> <i className='fa fa-facebook facebook'> </i> </Link> <Link to='#'> <i className='fa fa-twitter twitter'> </i> </Link> <Link to='#'> <i className='fa fa-linkedin linkedin'> </i> </Link> <Link to='#'> <i className='fa fa-google-plus google-plus'> </i> </Link> </div>
								</div>
							</div>
							<div className='col-md-4'>
								<div className='widget'>
									<h5>About Us</h5>
									<hr />
									<p>PBO</p>
									<p>The Premier Boxing Organisation use Independent World Boxing Rankings that are created by qualified knowledge. Boxers need to win meaningful fights against other rated fighters. If you can find errors please tell us, we do miss fights and sometimes this can affect the rankings. Name errors can also cause us problems so we apologise for any mistake and all information we believe to be correct. Let us know if you can find them, bear in mind the ratings are updated monthly to the nearest Monday of the new month. </p>
								</div>
							</div>
							<div className='col-md-4'>
								<div className='widget'>
									<h5>Useful Links</h5>
									<hr />
									<div className='row'>
										<div className='col-xs-6'>
											<ul>
												<li>
													<a href='http://www.wbcboxing.com/' target='_blank' rel='noreferrer'>wbcboxing.com </a>
												</li>
												<li>
													<a href='http://www.wbanews.com/' target='_blank' rel='noreferrer'>wbanews.com</a>
												</li>
												<li>
													<a href='http://www.ibf-usba-boxing.com/' target='_blank' rel='noreferrer'>ibf-usba-boxing.com</a>
												</li>
												<li>
													<a href='http://www.wboboxing.com/' target='_blank' rel='noreferrer'>wboboxing.com</a>
												</li>
												<li>
													<a href='http://www.ababoxing.us/' target='_blank' rel='noreferrer'>ababoxing.us</a>
												</li>
												<li>
													<a href='http://www.iboboxing.com/' target='_blank' rel='noreferrer'>iboboxing.com</a>
												</li>
												<li>
													<a href='http://www.eastsideboxing.com/' target='_blank' rel='noreferrer'>eastsideboxing.com</a>
												</li>
											</ul>
										</div>
										<div className='col-xs-6'>
											<ul>
												<li>
													<a href='http://www.fightnews.com/' target='_blank' rel='noreferrer'>fightnews.com</a>
												</li>
												<li>
													<a href='http://www.boxrec.com/' target='_blank' rel='noreferrer'>boxrec.com</a>
												</li>
												<li>
													<a href='http://www.the13thround.com/' target='_blank' rel='noreferrer'>the13thround.com</a>
												</li>
												<li> <a href='http://www.tbrb.org/' target='_blank' rel='noreferrer'>tbrb.org</a>
												</li>
												<li>
													<a href='http://www.saddoboxing.com/' target='_blank' rel='noreferrer'>saddoboxing.com</a>
												</li>
												<li>
													<a href='http://www.secondsout.com/' target='_blank' rel='noreferrer'>secondsout.com</a>
												</li>
												<li>
													<a href='http://www.boxingnews24.com/' target='_blank' rel='noreferrer'>boxingnews24.org</a>
												</li>
											</ul>
										</div>
										<div className='col-xs-6'>
										</div>
										<div className='clearfix'>&nbsp;</div>
									</div>
								</div>
							</div>
						</div>
						<hr />
						<p className='copy'>Copyright © 2014-2022 | <Link to='./'>Home</Link> | <Link to='/the-rules'>The Rules</Link> | <Link to='/latest-news'>Latest News</Link> | <Link to='/years-1991-2020'>World Title Fights</Link> | <Link to='/compliance'>Compliance</Link> | <Link to='/forum'>Forum</Link> | <Link to='/results'>Results</Link> | <Link to='/contact-us'>Contact Us</Link> | Built by <a href='http://honeywood.eu/' target='_blank' rel='noreferrer'>Honeywood</a>
						</p>
					</div>
					<div className='clearfix'>&nbsp;</div>
				</div>
			</div>
		</footer>
	)
}

export function CSS() {
	return <>
		<link href='//netdna.bootstrapcdn.com/bootstrap/3.1.1/css/bootstrap.min.css' rel='stylesheet' id='x-theme' />
		<link href='//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css' rel='stylesheet' />
	</>
}

export function Gloves() {
	return (
		<div className='gloves'>
			<Link to='/heavyweight-200' title='Display the rankings for the Heavyweight category'>
				<img src='https://premierboxingorganisation.com/files/pictures/boxing-gloves-hvy.jpg' alt='' width='25' className='glove' />
			</Link>
			<Link to='/cruiserweight-200' title='Display the rankings for the Cruiserweight 200 category'>
				<img src='./files/pictures/boxing-gloves-200.jpg' alt='' width='25' className='glove' />
			</Link>
			<Link to='/light-heavyweight-175' title='Display the rankings for the Light Heavyweight 175 category'>
				<img src='./files/pictures/boxing-gloves-175.jpg' width='25' alt='' className='glove' />
			</Link>
			<Link to='/super-middleweight-168' title='Display the rankings for the Super Middleweight 168 category'>
				<img src='./files/pictures/boxing-gloves-168.jpg' width='25' alt='' className='glove' />
			</Link>
			<Link to='/middleweight-160' title='Display the rankings for the Middleweight 160 category'>
				<img src='./files/pictures/boxing-gloves-160.jpg' alt='' width='25' className='glove' />
			</Link>
			<Link to='/junior-middleweight-154' title='Display the rankings for the Junior Middleweight 154 category'>
				<img src='./files/pictures/boxing-gloves-154.jpg' alt='' width='25' className='glove' />
			</Link>
			<Link to='/welterweight-147' title='Display the rankings for the Welterweight 147 category'>
				<img src='./files/pictures/boxing-gloves-147.jpg' alt='' width='25' className='glove' />
			</Link>
			<Link to='/junior-welterweight-140' title='Display the rankings for the Junior Weletrweight 140 category'>
				<img src='./files/pictures/boxing-gloves-140.jpg' alt='' width='25' className='glove' />
			</Link>
			<Link to='/lightweight-135' title='Display the rankings for the Lightweight 135 category'>
				<img src='./files/pictures/boxing-gloves-135.jpg' alt='' width='25' className='glove' />
			</Link>
			<Link to='/super-featherweight-130' title='Display the rankings for the Super Featherweight 130 category'>
				<img src='./files/pictures/boxing-gloves-130.jpg' alt='' width='25' className='glove' />
			</Link>
			<Link to='/featherweight-126' title='Display the rankings for the Featherweight 126 category'>
				<img src='./files/pictures/boxing-gloves-126.jpg' alt='' width='25' className='glove' />
			</Link>
			<Link to='/super-bantamweight-122' title='Display the rankings for the Super Bantam Weight 122 category'>
				<img src='./files/pictures/boxing-gloves-122.jpg' alt='' width='25' className='glove' />
			</Link>
			<Link to='/bantamweight-118' title='Display the rankings for the Bantam Weight 118 category'>
				<img src='./files/pictures/boxing-gloves-118.jpg' alt='' width='25' className='glove' />
			</Link>
			<Link to='/super-flyweight-115' title='Display the rankings for the Superflyweight 115 category'>
				<img src='./files/pictures/boxing-gloves-115.jpg' alt='' width='25' className='glove' />
			</Link>
			<Link to='/flyweight-112' title='Display the rankings for the Flyweight 112 category'>
				<img src='./files/pictures/boxing-gloves-112.jpg' alt='' width='25' className='glove' />
			</Link>
			<Link to='/junior-flyweight-108' title='Display the rankings for the Junior Flyweight 108 category'>
				<img src='./files/pictures/boxing-gloves-108.jpg' alt='' width='25' className='glove' />
			</Link>
			<Link to='/straw-weight-105' title='Display the rankings for the Strawweight 105 category'>
				<img src='./files/pictures/boxing-gloves-105.jpg' alt='' width='25' className='glove' />
			</Link>
		</div>
	)
}

export const categories = [ 'cruiserweight-200', 'heavyweight-200', 'light-heavyweight-175', 'super-middleweight-168', 'middleweight-160', 'junior-middleweight-154', 'welterweight-147', 'junior-welterweight-140', 'lightweight-135', 'super-featherweight-130', 'featherweight-126', 'super-bantamweight-122', 'bantamweight-118', 'super-flyweight-115', 'flyweight-112', 'junior-flyweight-108', 'straw-weight-105' ]

export function PreviousWorldTitleFights() {
	return <>
		<Masthead page={{ title: 'Previous World Title Fights' }} />
		<div className='article container'>
			<table class='table'>
				<tbody>
					<tr>
						<td><Link to='/world-title-fights-2020'>2020</Link></td>
						<td><Link to='/world-title-fights-2019'>2019</Link></td>
						<td><Link to='/world-title-fights-2018'>2018</Link></td>
						<td><Link to='/world-title-fights-2017'>2017</Link></td>
						<td><Link to='/world-title-fights-2016'>2016</Link></td>
					</tr>
					<tr>
						<td><Link to='/world-title-fights-2015'>2015</Link></td>
						<td><Link to='/world-title-fights-2014'>2014</Link></td>
						<td><Link to='/world-title-fights-2013'>2013</Link></td>
						<td><Link to='/world-title-fights-2012'>2012</Link></td>
						<td><Link to='/world-title-fights-2011'>2011</Link></td>
					</tr>
					<tr>
						<td><Link to='/world-title-fights-2010'>2010</Link></td>
						<td><Link to='/world-title-fights-2009'>2009</Link></td>
						<td><Link to='/world-title-fights-2008'>2008</Link></td>
						<td><Link to='/world-title-fights-2007'>2007</Link></td>
						<td><Link to='/world-title-fights-2006'>2006</Link></td>
					</tr>
					<tr>
						<td><Link to='/world-title-fights-2005'>2005</Link></td>
						<td><Link to='/world-title-fights-2004'>2004</Link></td>
						<td><Link to='/world-title-fights-2003'>2003</Link></td>
						<td><Link to='/world-title-fights-2002'>2002</Link></td>
						<td><Link to='/world-title-fights-2001'>2001</Link></td>
					</tr>
					<tr>
						<td><Link to='/world-title-fights-2000'>2000</Link></td>
						<td><Link to='/world-title-fights-1999'>1999</Link></td>
						<td><Link to='/world-title-fights-1998'>1998</Link></td>
						<td><Link to='/world-title-fights-1997'>1997</Link></td>
						<td><Link to='/world-title-fights-1996'>1996</Link></td>
					</tr>
					<tr>
						<td><Link to='/world-title-fights-1995'>1995</Link></td>
						<td><Link to='/world-title-fights-1994'>1994</Link></td>
						<td><Link to='/world-title-fights-1993'>1993</Link></td>
						<td><Link to='/world-title-fights-1992'>1992</Link></td>
						<td><Link to='/world-title-fights-1991'>1991</Link></td>
					</tr>
				</tbody>
			</table>
		</div>
	</>
}

export function Lineage() {
	return <>
		<Masthead page={{ title: 'Lineage' }} />
		<div className='article container'>
			<table className='table' width='100%'>
				<tbody>
					<tr>
						<td><Link to='/lineage-WBA'>WBA</Link></td>
						<td><Link to='/lineage-WBC'>WBC</Link></td>
						<td><Link to='/lineage-WBO'>WBO</Link></td>
						<td><Link to='/lineage-IBF'>IBF</Link></td>
						<td><Link to='/lineage-IBO'>IBO</Link></td>
					</tr>
					<tr>
						<td><Link to='/lineage-HEAVYWEIGHT'>HEAVYWEIGHT</Link> </td>
						<td><Link to='/lineage-CRUISERWEIGHT'>CRUISERWEIGHT</Link></td>
						<td><Link to='/lineage-LIGHT-HEAVYWEIGHT'>LIGHT-HEAVYWEIGHT</Link> </td>
						<td><Link to='/lineage-SUPER-MIDDLEWEIGHT'>SUPER-MIDDLEWEIGHT</Link> </td>
						<td><Link to='/lineage-MIDDLEWEIGHT'>MIDDLEWEIGHT</Link></td>
					</tr>
					<tr>
						<td><Link to='/lineage-JUNIOR-MIDDLEWEIGHT'>JUNIOR-MIDDLEWEIGHT</Link> </td>
						<td><Link to='/lineage-WELTERWEIGHT'>WELTERWEIGHT</Link></td>
						<td><Link to='/lineage-JUNIOR-WELTERWEIGHT'>JUNIOR-WELTERWEIGHT</Link> </td>
						<td><Link to='/lineage-LIGHTWEIGHT'>LIGHTWEIGHT</Link> </td>
						<td><Link to='/lineage-SUPER-FEATHERWEIGHT'>SUPER-FEATHERWEIGHT</Link> </td>
					</tr>
					<tr>
						<td><Link to='/lineage-FEATHERWEIGHT'>FEATHERWEIGHT</Link></td>
						<td><Link to='/lineage-SUPER-BANTAMWEIGHT'>SUPER-BANTAMWEIGHT</Link> </td>
						<td><Link to='/lineage-BANTAMWEIGHT'>BANTAMWEIGHT</Link></td>
						<td><Link to='/lineage-SUPER-FLYWEIGHT'>SUPER-FLYWEIGHT</Link></td>
						<td><Link to='/lineage-FLYWEIGHT'>FLYWEIGHT</Link></td>
					</tr>
					<tr>
						<td><Link to='/lineage-JUNIOR-FLYWEIGHT'>JUNIOR-FLYWEIGHT</Link></td>
						<td><Link to='/lineage-STRAW-WEIGHT'>STRAW-WEIGHT</Link></td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
					</tr>
				</tbody>
			</table>
		</div>
	</>
}

export function Masthead({ page = null, title = 'Independent World Boxing Rankings' }) {
	return (
		<div className='masthead jumbotron'>
			<div className='container'>
				{page?.title
					? <h1>{page.title} <small>{title}</small></h1>
					: title}
			</div>
		</div>
	)
}
