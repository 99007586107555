import Amplify, { DataStore, Hub } from 'aws-amplify'
import { Routes, Route, BrowserRouter as Router, useParams } from 'react-router-dom'
import { createContext, useContext, useEffect, useState } from 'react'
import { Menu, Footer, categories, Gloves, Lineage, Masthead, PreviousWorldTitleFights } from './UI'
import { Page } from './models'
import awsmobile from './aws-exports'

import './index.css'
import './css/default.css'
import './css/footer.css'
import './css/theme.css'
import './Webpage.scss'

Amplify.configure(awsmobile)

const resultsPattern = /(.*-[\d]+|compliance-[a-z]|lineage-.*$)|(world-title-fights|non-compliant-fighters|compliance)/i

const DC = createContext(null)

export default function Webpage() {
	const [ ready, setReady ] = useState(false)
	useEffect(() => {
		const sync = ({ payload: { event } }) => event === 'ready' && setReady(true)
		Hub.listen('datastore', sync)
		return () => Hub.remove('datastore', sync)
	}, [ ])
	return (
		<DC.Provider value={ready}>
			<Router>
				<Routes>
					<Route element={<Menu />} path='/:link' />
					<Route element={<Menu />} path='/' />
				</Routes>
				<Routes>
					<Route element={<PreviousWorldTitleFights />} path='/years-1991-2020' />
					<Route element={<Lineage />} path='/lineage' />
					<Route element={<Article />} path='/:link' />
					<Route element={<Article />} path='/' />
				</Routes>
				<Footer />
			</Router>
		</DC.Provider>
	)
}

function Results() {
	const params = useParams()
	const [ results, setResults ] = useState('')
	useEffect(() => { fetch(`https://premierboxingorganisation.com/api.php?q=${params.link}`).then(r => r.text()).then(setResults) }, [ params ])
	return (
		<div dangerouslySetInnerHTML={{ __html: results }} />
	)
}

function Article() {
	const ready = useContext(DC)
	const params = useParams()
	const link = params.link || 'front-page'
	const [ page, setPage ] = useState()
	useEffect(() => { DataStore.query(Page, p => p.link('eq', link)).then(p => setPage(p.length ? p[0] : null)) }, [ link, ready ])
	const showResults = resultsPattern.test(link)
	if (!page && showResults) return <>
		<Masthead page={{ title: link.replaceAll('-', ' ') }} />
		<Results />
	</>
	const showGloves = categories.includes(link)
	if (showGloves) return <>
                <Masthead page={page} />
                <div className='results-header'>
                        <div className='article' data-category={showGloves} dangerouslySetInnerHTML={{ __html: page?.html }} />
                        <Gloves />
                </div>
		<style type="text/css">
		{`footer { margin-top: -20px }`}
		</style>
		{showResults && <Results />}
        </>
        return <>
                <Masthead page={page} />
                {showResults && <Results />}
        </>
}

